<template>
	<div class="login-wrap">
		<div class="login-container">
			<div class="flex-row align-center logo-sec">
				<div class="logo-left"></div>
				<img src="../assets/logo_icon.png" class="logo-img"/>
				<div class="logo-dian"></div>
				<div class="logo-text">META</div>
				<div class="logo-text-right">联盟链</div>
				<div class="logo-right"></div>
			</div>
			<div class="flex-row align-center">
				<div @click="change(1)" class="login-tag-item" :class="type==1?'login-tag-item-active':''">登录</div>
				<div class="xian"></div>
				<div @click="change(2)" class="login-tag-item" :class="type==2?'login-tag-item-active':''">注册</div>
				<!-- <div class="login-tag-item" :class="type==2?'login-tag-item-active':''">注册</div> -->
			</div>
			<div class="register-sec flex-column" v-if='type ==2'>
				<input class="fill-input-item" placeholder="昵称" v-emoji @input="inputRegisterNickname"
					v-model="register.nickName" maxlength="10" oninput="if(value.length > 10)value = value.slice(0, 10)" />
				<input class="fill-input-item" @input="inputRegisterName" v-model="register.name" placeholder="姓名" />
				<input class="fill-input-item" @input="inputRegisterCard" v-model="register.card" placeholder="身份证号码" />
				<div class="card-explain">*您的身份信息用于购买数字藏品时，签署法律认可的智能合约。</div>
				<!-- <div class="card-explain">注意：提现人必须和注册人一致，不一致将无法提现。</div> -->
				<!-- <input class="fill-input-item" type="number" v-model="register.bankNo" placeholder="银行卡号" /> -->

				<div class="flex-row align-center fill-phone-sec">
					<input type="number" oninput="if(value.length > 11)value = value.slice(0, 11)" maxlength="11"
						@input="inputRegisterMobile" v-model="register.mobile" class="fill-phone" placeholder="手机号码" />
					<div class="get-code" @click="getVerifyCode()" :class="register.canSend ? 'get-code-sel':''">
						{{register.msg}}
					</div>
				</div>
				<input type="number" maxlength="6" oninput="if(value.length > 6)value = value.slice(0, 6)"
					class="fill-input-item" @input="inputRegisterCode" v-model="register.code" placeholder="验证码" />
				<div class="flex-row align-center agreen-sec">
					<div class="agreen-boder" @click="agreement()"><img v-show="register.agreement"
							src='../assets/agreen_icon.png' class='agreen-icon' /></div>
					<div class="agreement-text">我已认真阅读并同意</div>
					<div class="agreement-file" @click.stop="navAgreement(6)">《用户协议》</div>
					<div class="agreement-text">和</div>
					<div class="agreement-file" @click.stop="navAgreement(5)">《隐私政策》</div>
				</div>
				<div class="submit-btn-register" @click="doRegister">注册</div>
			</div>
			<div class="login-sec flex-column" v-else>
				<div class="flex-row align-center fill-phone-sec mt40">
					<input type="number" oninput="if(value.length > 11)value = value.slice(0, 11)" maxlength="11"
						class="fill-phone" @input="inputLoginMobile" v-model="login.mobile" placeholder="手机号码" />
					<div class="get-code" :class="login.canSend ? 'get-code-sel' :''" @click="getVerifyCode()">
						{{login.msg}}
					</div>
				</div>
				<input type="number" oninput="if(value.length > 6)value = value.slice(0, 6)" maxlength="6"
					class="fill-input-item" v-model="login.code" placeholder="验证码" />
				<div class="submit-btn" @click="doLogin()">登录</div>
				<div class="flex-row align-center login-agreen-sec">
					<div class="agreement-text">*登录即代表您同意</div>
					<div class="agreement-file" @click.stop="navAgreement(6)">《用户协议》</div>
					<div class="agreement-text">和</div>
					<div class="agreement-file" @click.stop="navAgreement(5)">《隐私政策》</div>
				</div>
			</div>
		</div>
		<div class="code-wrap flex-column" v-if="showVerify">
			<div class="code-title">请输入验证码</div>
			<img :src="base64code" @click.stop="getVerifyCode" alt="" class="code-img">
			<div class="code-tips">看不清？点击图片换一张</div>
			<div class="code-input-wrap">
				<input type="number" oninput="if(value.length > 11)value = value.slice(0, 11)" maxlength="11"
					v-model="verifyCode" placeholder="请输入验证码" />
			</div>
			<div class="send-code-btn" @click="sendCode()">确定</div>
		</div>
		<div class="verify-model" v-if="showVerify" @click.stop="closeVerifyModal()"></div>
		<div class="model" v-on:click="$emit('close-login')"></div>
	</div>

</template>

<script>
	import Vue from 'vue';
	import Vant from 'vant';
	import 'vant/lib/index.css';
	
	Vue.use(Vant);
	import config from '../config/config.js';
	import util from '../config/util.js'
	export default {
		name: 'HelloWorld',
		props: {
			msg: String
		},
		data() {
			return {
				showVerify: 0,
				type: 1,
				login: {
					mobile: '',
					code: '',
					time: 0,
					msg: '获取验证码',
					canSend: 0,
					sending: 0,
					logining: 0,
					interval: ''
				},
				register: {
					mobile: '',
					code: '',
					name: '',
					card: '',
					agreement: 0,
					time: 0,
					msg: '获取验证码',
					canSend: 0,
					sending: 0,
					logining: 0,
					interval: '',
					bankNo: ''
				},
				base64code: '',
				verifyKey: '',
				verifyCode: '',
			}
		},
		async mounted() {
			this.login = {
				mobile: '',
				code: '',
				time: 0,
				msg: '获取验证码',
				canSend: 0,
				sending: 0,
				logining: 0,
				interval: ''
			};
			this.register = {
				mobile: '',
				code: '',
				nickName: '',
				name: '',
				card: '',
				agreement: 0,
				time: 0,
				msg: '获取验证码',
				canSend: 0,
				sending: 0,
				logining: 0,
				interval: '',
				bankNo: ''
			}
		},
		methods: {
			getVerifyCode() {
				const that = this;
				if (this.type == 1) {
					if(!that.login.canSend){
						that.$toast('请输入手机号');
						return;
					}
				} else {
					if(!that.register.canSend){
						that.$toast('请输入手机号');
						return;
					}
				}
				let params = new FormData()
				this.axios.post(config.requestUrl + '/search/getImage', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.showVerify=1;
						that.verifyKey = response.data.obj.key;
						that.base64code = response.data.obj.data
						console.log(response)
					} else {
						that.$toast.fail(response.data.msg);
					}
				}, response => {
					let msg = response.data.msg;
					const str = "未获取到用户信息";
					const str2 = "登录已失效"
					if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
						localStorage.removeItem("user");
						that.$store.commit("clearUser");
						that.$store.commit("clearCode");
					}
					that.$toast.fail(response.data.msg);
				})
			},
			sendCode() {
				const that = this;
				if (that.verifyCode == '') {
					this.$toast('请输入验证码')
					return;
				}
				this.showVerify = 0;
				if (this.type == 1) {
					this.sendLoginCode()
				} else {
					this.sendRegisterCode()
				}
			},
			closeVerifyModal() {
				this.showVerify = 0;
			},
			change(type) {
				this.type = type;
			},
			agreement() {
				this.register.agreement = !this.register.agreement
			},
			inputLoginMobile(e) {
				this.login.mobile = e.target.value
				this.checkLoginCanSend();
			},
			inputLoginCode(e) {
				this.login.code = e.target.value
			},
			sendLoginCode() {
				// , {
				// 	headers: {
				// 		'Content-Type': 'multipart/form-data'
				// 	}
				// }
				const that = this;
				let params = new FormData()
				params.append('phoneEmail', that.login.mobile)
				params.append('smsType', 1)
				params.append('zone', 86)
				params.append('doType', 1)
				params.append('codeValue', that.verifyCode)
				params.append('key', that.verifyKey)
				if (that.login.canSend == 1 && !that.login.sending) {
					that.login.sending = 1;
					that.$toast.loading({
						message: '发送中...',
						forbidClick: true,
						duration: 0,
					});
					that.axios.post(config.requestUrl + '/ajax/sendCode', params, {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					}).then(response => {
						that.login.sending = 0;
						if (response.data.code == 0) {
							that.$toast.success('发送成功');
							that.login.time = 60;
							that.login.canSend = false;
							that.login.interval = setInterval(() => {
								if (that.login.time <= 0) {
									that.login.msg = '获取验证码'
									clearInterval(this.login.interval);
									that.checkLoginCanSend();
								} else {
									that.login.time = that.login.time - 1;
									that.login.msg = `${that.login.time}s`
								}
							}, 1000)
						} else {
							that.$toast(response.data.msg);
						}

					}, response => {
						console.log(response)
						this.login.sending = 0;
						that.$toast('获取失败，请稍后重试');
					})

				}
			},
			checkLoginCanSend() {
				if ((/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.login.mobile)) && this.login.time == 0) {
					this.login.canSend = 1;
				}
			},
			doLogin() {
				const that =this;
				if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(that.login.mobile))) {
					that.$toast('请输入正确的手机号')
					return;
				}
				if (!that.login.code) {
					that.$toast('请输入验证码')
					return;
				}
				if (!that.login.logining) {
					console.log(that.$store.state.user)
					let params = new FormData()
					params.append('phone', that.login.mobile)
					params.append('verifyCode', that.login.code)
					// params.append('code', this.$store.state.code)
					params.append('openId', that.$store.state.user.openid ? that.$store.state.user.openid : '')
					that.login.logining = 1;
					that.$toast.loading({
						message: '登录中...',
						forbidClick: true,
						duration: 0,
					});
					that.axios.post(config.requestUrl + '/front/weChatLogin', params, {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					}).then(response => {
						that.login.logining = 0;
						if (response.data.code == 0) {
							that.$toast.success('登录成功');
							let user = response.data.obj;
							user.randName = util.randomString();
							that.$store.commit('setUser', user)

							that.$store.commit('clearCode')
							localStorage.setItem('user', JSON.stringify(user))
							setTimeout(() => {
								that.$parent.closeLogin();
							}, 1200)
						} else {
							that.$toast(response.data.msg);
						}
					}, response => {
						console.log(response)
						that.login.logining = 0;
						that.$toast('登录失败，请稍后重试');
					})
				}
			},
			sendRegisterCode() {
				const that = this;

				let params = new FormData()
				params.append('phoneEmail', this.register.mobile)
				params.append('smsType', 1)
				params.append('zone', 86)
				params.append('doType', 2)
				params.append('codeValue', that.verifyCode)
				params.append('key', that.verifyKey)
				if (this.register.canSend == 1 && !this.register.sending) {
					this.register.sending = 1;
					that.$toast.loading({
						message: '发送中...',
						forbidClick: true,
					});
					this.axios.post(config.requestUrl + '/ajax/sendCode', params, {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					}).then(response => {
						this.register.sending = 0;
						if (response.data.code == 0) {
							that.$toast.success('发送成功');
							this.register.time = 60;
							this.register.canSend = false;
							this.register.interval = setInterval(() => {
								if (this.register.time <= 0) {
									this.register.msg = '获取验证码'
									clearInterval(this.register.interval);
									this.checkRegisterCanSend();
								} else {
									this.register.time = this.register.time - 1;
									this.register.msg = `${this.register.time}s`
								}
							}, 1000)
						} else {
							that.$toast(response.data.msg);
						}
						console.log("获取信息成功");
						console.log(response);
					}, response => {
						this.register.sending = 0;
						that.$toast('获取失败，请稍后重试');
						console.log("获取信息失败");
						console.log(response);
					})

				}
			},
			inputRegisterMobile(e) {
				console.log(e)
				this.register.mobile = e.target.value;
				this.checkRegisterCanSend();
			},
			inputRegisterCode(e) {
				this.register.code = e.target.value;
			},
			checkRegisterCanSend() {
				if ((/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.register.mobile)) && this.register.time == 0) {
					this.register.canSend = 1;
				}
			},
			inputRegisterName(e) {
				this.register.name = e.target.value;
			},
			inputRegisterNickname(e) {
				let nickName = e.target.value;
				console.log(nickName)
				console.log('替换后：' + nickName.replaceAll(/[\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/, ''))
				this.register.nickName = nickName.replaceAll(/[\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/, '')
			},
			inputRegisterCard(e) {
				this.register.card = e.target.value;
			},
			doRegister() {
				const that = this
				if (!that.register.nickName) {
					that.$toast('请输入昵称')
					return;
				}
				if (!that.register.name) {
					that.$toast('请输入姓名')
					return;
				}
				if (!(/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(that.register.card))) {
					that.$toast('请输入正确的身份证号')
					return;
				}
				// if (!that.register.bankNo) {
				// 	Toast('请输入银行卡号')
				// 	return;
				// }
				if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(that.register.mobile))) {
					that.$toast('请输入正确的手机号')
					return;
				}
				if (!that.register.code) {
					that.$toast('请输入验证码')
					return;
				}
				if (!that.register.agreement) {
					that.$toast('请阅读并查看协议')
					return;
				}

				if (!that.register.logining) {
					let params = new FormData()
					params.append('phone', that.register.mobile)
					params.append('verifyCode', that.register.code)
					params.append('idNo', that.register.card)
					params.append('name', that.register.name)
					params.append('nickName', that.register.nickName)
					params.append('openId', that.$store.state.user.openid ? that.$store.state.user.openid : '')
					// params.append('inviteCode', sessionStorage.getItem('inviteCode') ? sessionStorage.getItem(
					// 	'inviteCode') : '')
					params.append('inviteCode', '')
					that.login.logining = 1;
					that.$toast.loading({
						message: '注册中...',
						forbidClick: true,
						duration: 0,
					});
					let djs = setTimeout(() => {
						that.$toast.loading({
							message: '正在上链...',
							forbidClick: true,
							duration: 0,
						});
						djs = setTimeout(() => {
							that.$toast.loading({
								message: '正在生成区块链地址...',
								forbidClick: true,
								duration: 0,
							});
						}, 5000)
					}, 2000)
					that.axios.post(config.requestUrl + '/front/doRegisterFromWeChat', params, {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					}).then(async response => {
						that.$toast.clear();
						clearTimeout(djs);
						that.login.logining = 0;
						if (response.data.code == 0) {
							that.login.logining = 0;
							let user = response.data.obj;
							user.randName = util.randomString();
							that.$store.commit('setUser', user)
							that.$store.commit('clearCode')
							localStorage.setItem('user', JSON.stringify(user))
							that.$toast.success('登录成功');
							setTimeout(() => {
								setTimeout(() => {
									that.$parent.closeLogin();
								}, 1200)
							}, 1200)
						} else {
							that.$toast(response.data.msg);
						}
					}, response => {
						console.log(response)
						that.login.logining = 0;
						that.$toast('失败，请稍后重试');
					})
				}
			},
			updateUserAccountAndKey(address, privateKey) {
				let params = new FormData()
				params.append('address', address)
				params.append('privateKey', privateKey)
				this.axios.post(config.requestUrl + '/front/updateUserAccountAndKey', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(async response => {
					if (response.data.code == 0) {
						that.login.logining = 0;
						that.$toast.success('登录成功');
						setTimeout(() => {
							setTimeout(() => {
								this.$parent.closeLogin();
							}, 1200)
						}, 1200)
					} else {
						that.$toast(response.data.msg);
					}
				}, response => {
					console.log(response)
					that.login.logining = 0;
					that.$toast('失败，请稍后重试');
				})
			},
			navAgreement(type) {
				this.$router.push({
					path: "/agreement",
					query: {
						type
					}
				});
			}
		},
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.login-container {
		width: 650px;
		height: 1270px;
		max-height: 90%;
		overflow: scroll;
		background: #FFFFFF;
		/* background: #999999; */
		border-radius: 22px;
		padding: 45px 30px 45px 30px;
		position: fixed;
		top: 67px;
		left: 0;
		right: 0;
		margin: 0 auto;
		box-sizing: border-box;
		z-index: 1001;
	}
	
	.logo-sec{
		margin-bottom: 54px;
	}
	.logo-left{
		width: 100px;
		height: 2px;
		background: linear-gradient(270deg, #FFFFFF 0%, #D8D8D8 100%);
		margin-right: 40px;
		margin-left: 53px;
	}
	.logo-img{
		width: 31px;
		height: 31px;
		margin-right: 8px;
	}
	.logo-dian{
		width: 4px;
		height: 4px;
		background: #000000;
		margin-right: 10px;
	}
	.logo-text{
		font-size: 26px;
		color: #000000;
		line-height: 30px;
	}
	.logo-text-right{
		font-size: 26px;
		color: #000000;
		line-height: 30px;
		margin-left: 4px;
	}
	.logo-right{
		width: 100px;
		height: 2px;
		background: linear-gradient(270deg, #FFFFFF 0%, #D8D8D8 100%);
		margin-left: 40px;
	}

	.login-tag-item {
		font-size: 38px;
		font-family: ZhenyanGB-Regular, ZhenyanGB;
		font-weight: 400;
		color: #000000;
		line-height: 39px;
		letter-spacing: 2px;
		width: 325px;
		text-align: center;
		font-weight: bold;
	}

	.login-tag-item-active {
		color: #6236FF;
	}

	.xian {
		width: 1px;
		height: 69px;
		background: #000000;
	}

	.register-sec {}

	.fill-input-item {
		width: 590px;
		height: 100px;
		border-radius: 60px;
		border: 1px solid #EEEEEE;
		font-size: 28px;
		color: #000000;
		line-height: 40px;
		letter-spacing: 1px;
		padding-left: 30px;
		box-sizing: border-box;
		margin-top: 40px;
	}

	.card-explain {
		font-size: 18px;
		color: #999999;
		line-height: 25px;
		margin-top: 2px;
		margin-left: 30px;
	}

	.fill-phone-sec {
		margin-top: 13px;
		width: 590px;
		height: 100px;
		border-radius: 60px;
	}

	.fill-phone {
		width: 409px;
		height: 98px;
		font-size: 28px;
		color: #000000;
		line-height: 98px;
		padding-left: 30px;
		border-top-left-radius: 60px;
		border-bottom-left-radius: 60px;
		border: 1px solid #EEEEEE;
		box-sizing: border-box;
	}

	.get-code {
		width: 179px;
		height: 98px;
		background: #6236FF;
		font-size: 28px;
		color: #FFFFFF;
		line-height: 40px;
		letter-spacing: 1px;
		text-align: center;
		line-height: 98px;
		border-top-right-radius: 60px;
		border-bottom-right-radius: 60px;
		box-sizing: border-box;
	}

	.get-code-sel {
		color: #000000;
	}

	.agreen-sec {
		margin-top: 20px;
	}

	.agreen-boder {
		width: 27px;
		height: 27px;
		border: 1px solid #999999;
		margin-right: 10px;
	}

	.agreen-icon {
		width: 27px;
		height: 27px;
	}

	.agreement-text {
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		line-height: 25px;
	}

	.agreement-file {
		font-size: 18px;
		color: #FF5E38;
	}

	.submit-btn {
		width: 590px;
		height: 100px;
		background: #6236FF;
		border-radius: 60px;
		font-size: 38px;
		color: #FFFFFF;
		letter-spacing: 2px;
		text-align: center;
		line-height: 100px;
		margin-top: 536px;
	}

	.submit-btn-register {
		width: 590px;
		height: 100px;
		background: #6236FF;
		border-radius: 60px;
		font-size: 38px;
		color: #FFFFFF;
		letter-spacing: 2px;
		text-align: center;
		line-height: 100px;
		margin-top: 100px;
	}

	.login-agreen-sec {
		margin: 10px auto 0 auto;
	}

	.mt40 {
		margin-top: 40px;
	}

	/* 蒙层 */
	.verify-model {
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.7);
		width: 100%;
		height: 100%;
		z-index: 1001;
	}

	.code-wrap {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		z-index: 1002;
		background: #FFFFFF;
		width: 500px;
		height: max-content;
		border-radius: 10px;
		padding: 40px 30px;
		box-sizing: border-box;
	}

	.code-title {
		font-size: 30px;
		font-weight: bold;
		text-align: center;
	}

	.code-img {
		width: 400px;
		height: 200px;
		display: flex;
		margin: 20px auto 15px;
	}

	.code-tips {
		width: 100%;
		text-align: center;
		font-size: 22px;
		color: #999999;
	}

	.code-input-wrap {
		width: 440px;
		height: 100px;
		border-radius: 60px;
		border: 1px solid #EEEEEE;
		font-size: 28px;
		color: #000000;
		line-height: 40px;
		letter-spacing: 1px;
		padding-left: 30px;
		box-sizing: border-box;
		margin-top: 30px;
	}

	.code-input-wrap input {
		width: 380px;
		height: 94px;
		font-size: 26px;
		color: #000000;
		line-height: 40px;
		padding: 0 30px;
		border-top-left-radius: 30px;
		border-bottom-left-radius: 30px;
		border: none;
		box-sizing: border-box;
	}

	.send-code-btn {
		margin-top: 80px;
		width: 440px;
		height: 80px;
		background: #6236FF;
		border-radius: 40px;
		font-size: 30px;
		color: #FFFFFF;
		letter-spacing: 2px;
		text-align: center;
		line-height: 80px;
	}
</style>
